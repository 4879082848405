<template>
  <section class="main-section cabinet-section">
    <div class="cabinet-1">
      <div class="cabinet-1-img font-0"><img :src="ossPath + 'cabinet-1.png'" class="full-img" /></div>
      <div class="cabinet-label font-0"><img :src="ossPath + 'cabinet-label-1.png'" class="full-img" /></div>
    </div>
    <div class="cabinet-content">
      <div class="cabinet-header">JUNETA+ 云系列</div>
      <div class="cabinet-title">整体线条明晰</div>
      <div class="cabinet-desc">
        <p>超哑的各类表⾯材质给予您舒适之感</p>
        <p>明晰的线条感</p>
        <p>彰显新颖纯的设计</p>
        <p>紧跟最新潮流</p>
      </div>
    </div>
    <div class="cabinet-bg-bottom"><div></div></div>
    <div class="cabinet-2">
      <div class="cabinet-2-img font-0"><img :src="ossPath + 'cabinet-2.png'" class="full-img" /></div>
      <div class="cabinet-2-content cabinet-right">
        <div class="cabinet-header">DURO+ 磐系列</div>
        <div class="cabinet-title">水晶般清澈 岩石般坚硬</div>
        <div class="cabinet-desc">
          <p>后现代极简主义的设计风格</p>
          <p>硬朗不凡的⽓质</p>
          <p>让空间更加美观⼤⽓</p>
        </div>
      </div>
    </div>
    <div class="cabinet-bg-bottom cabinet-bg-bottom-left"><div></div></div>
    <div class="cabinet-3">
      <div class="cabinet-3-img font-0"><img :src="ossPath + 'cabinet-3.png'" class="full-img" /></div>
      <div class="cabinet-3-label font-0"><img :src="ossPath + 'cabinet-label-2.png'" class="full-img" /></div>
    </div>
    <div class="cabinet-content">
      <div class="cabinet-header">QUADRO+ 锦系列</div>
      <div class="cabinet-title">极具美感的造型</div>
      <div class="cabinet-desc">
        <p>玛娅橱柜装饰风格为传统欧式风格</p>
        <p>注入了现代极简的设计和构图元素</p>
        <p>辅以经典门板造型</p>
      </div>
    </div>
    <div class="cabinet-bg-bottom"><div></div></div>
    <div class="cabinet-4">
      <div class="cabinet-4-img font-0"><img :src="ossPath + 'cabinet-4.png'" class="full-img" /></div>
      <div class="cabinet-4-content cabinet-right">
        <div class="cabinet-header">LIGNO+ 森系列</div>
        <div class="cabinet-title">自然风情</div>
        <div class="cabinet-desc">
          <p>将⾃然的风情融⼊家居空间</p>
          <p>让空间绽放出美丽的⾃然风景</p>
        </div>
      </div>
    </div>
    <div class="cabinet-bg-bottom cabinet-bg-bottom-left"><div></div></div>
  </section>
</template>
<script>
export default {
  data: () => ({
    ossPath: 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/space/'
  }),
  created () {
    this.$store.commit('updateActiveNav', 1)
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/space.css';
</style>
